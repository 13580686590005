const defaultLocale = "it"

const i18nPath = {
  it: {
    category: "categoria",
    search: "cerca",
  },
  en: {
    category: "category",
    search: "search",
  },
  "en-US": {
    category: "category",
    search: "search",
  },
}

export function getPagePath(page, locale) {
  const pageLocale = locale || page.locale
  let lang = pageLocale === defaultLocale ? "/" : `/${pageLocale}/`
  let path = `${page._allSlugLocales.find(x => x.locale === pageLocale).value}`
  if (page.root) {
    return lang + `${path}/`
  }
  path = `${
    page.treeParent._allSlugLocales.find(x => x.locale === pageLocale).value
  }/${path}`
  if (page.treeParent.root) {
    return lang + `${path}/`
  }
  path = `${
    page.treeParent.treeParent._allSlugLocales.find(
      x => x.locale === pageLocale
    ).value
  }${path}`
  return lang + `${path}/`
}

export function getArticlePath(page) {
  return `/blog/${page.slug}/`
}

export function getBlogPath(page) {
  return `/${page.slug}/`
}

export function getHomePath(locale) {
  return locale === defaultLocale ? "/" : `/${locale}/`
}
