import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"

export const useContacts = () => {
  const contacts = useStaticQuery(graphql`
    query ContactsQuery {
      allDatoCmsContatti {
        nodes {
          id
          email
          telefono
          locale
        }
      }
    }
  `)

  const locale = React.useContext(LanguageSwitcherContext).activeLocale

  const i18nContacts = contacts.allDatoCmsContatti.nodes.filter(
    link => link.locale === locale
  )


  return i18nContacts[0]
}
