import { Box } from "@theme-ui/components"
import React from "react"
import Nav from "./nav"

const Header = ({ isHome }) => {
  return (
    <Box
      as="header"
      sx={{
        backgroundColor: "light",
        position: "relative",
        width: "100%",
        zIndex: "9999",
      }}
    >
      <Nav  isHome={isHome}/>
    </Box>
  )
}

export default Header
