import { getBlogPath, getPagePath } from "./path"

function linkSwitch(item, locale) {
  if (item) {
    if (item.link) {
      switch (item.link.model.apiKey) {
        case "page":
          return {
            href: getPagePath(item.link, locale),
            label: item.anchor,
          }
        case "blog_page":
          return {
            href: getBlogPath(item.link),
            label: item.anchor,
          }
        default:
          return null
      }
    }
    return {
      href: item.url,
      label: item.anchor,
    }
  }
}

export default linkSwitch
