import { Box, Container, Flex, Image, Text } from "@theme-ui/components"
import React, { useState } from "react"
import { useMenu } from "../hooks/useMenu"
import { useContacts } from "../hooks/useContacts"
import { getHomePath } from "../utils/path"
import { InboundLink, OutboundLink } from "./link"
import logo from "../images/logo-caldofacile.svg"
import linkSwitch from "../utils/linkSwitch"
import { Mail, Phone, Menu, X } from "react-feather"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import * as Scroll from "react-scroll"
import { motion, AnimatePresence } from "framer-motion"

const Nav = ({ isHome }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const locale = React.useContext(LanguageSwitcherContext).activeLocale
  const menu = useMenu()
  const contacts = useContacts()
  let LinkScroll = Scroll.Link

  return (
    <Box as="nav" sx={{ width: "100%" }}>
      <Box sx={{ backgroundColor: "secondary", width: "100%" }}>
        <Container sx={{ py: [2, 2, 2, 2] }}>
          <Flex sx={{ alignItems: "center", justifyContent: "space-between" }}>
            {/* <LanguageSwitcher /> */}
            <Box></Box>
            <Box sx={{ color: "textLight", fontWeight: "bold", pl: [5] }}></Box>
          </Flex>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "grayBackground", width: "100%" }}>
        <Container sx={{ py: [2, 2, 2, 2], pb: [2, 2, 2, 5] }}>
          <Flex sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Box sx={{ paddingX: 2, paddingY: 3 }}>
              <InboundLink to={getHomePath(locale)}>
                <Image
                  src={logo}
                  variant="avatar"
                  alt="Caldofacile Logo"
                  sx={{ width: ["80%", "80%"] }}
                />
              </InboundLink>
            </Box>
            <Box sx={{ display: ["none", "none", "none", "block"] }}>
              <Flex>
                {contacts.email && (
                  <Box sx={{ color: "text", fontWeight: "bold" }}>
                    <OutboundLink
                      sx={{ display: "flex", alignItems: "center" }}
                      href={"mailto:" + contacts.email}
                    >
                      <Mail />
                      <Text sx={{ pl: [1] }}>{contacts.email}</Text>
                    </OutboundLink>
                  </Box>
                )}
                {contacts.telefono && (
                  <Box sx={{ color: "text", fontWeight: "bold", pl: [5] }}>
                    <OutboundLink
                      sx={{ display: "flex", alignItems: "center" }}
                      href={"tel:" + contacts.telefono.replace(/ /g, "")}
                    >
                      <Phone />
                      <Text sx={{ pl: [1] }}>{contacts.telefono}</Text>
                    </OutboundLink>
                  </Box>
                )}
                <Box sx={{ pl: [5] }}>
                  <LinkScroll
                    variant="buttons.full.primary"
                    to="contactForm"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={() => {
                      if (typeof window !== "undefined") {
                        window.dataLayer = window.dataLayer || []
                        window.dataLayer.push({
                          event: "riciedi_info",
                          formType: "Contact",
                          pageUrl: window.location.href,
                        })
                      }
                    }}
                  >
                    <InboundLink variant="buttons.full.primary">
                      Richiedi info +
                    </InboundLink>
                  </LinkScroll>
                </Box>
              </Flex>
            </Box>
            <Box sx={{ display: ["block", "block", "block", "none"] }}>
              {showMobileMenu ? (
                <X size="32" onClick={() => setShowMobileMenu(false)} />
              ) : (
                <Menu size="32" onClick={() => setShowMobileMenu(true)} />
              )}
            </Box>
          </Flex>
        </Container>
      </Box>
      <Container
        sx={{
          position: "relative",
          height: 0,
          py: "0!important",
          p: [0, 0, 0, 4],
          marginTop: [0, 0, 0, "-45px"],
        }}
      >
        <AnimatePresence>
          {showMobileMenu && <motion.div></motion.div>}
        </AnimatePresence>
        <Box
          onClick={() => setShowMobileMenu(false)}
          sx={{
            py: [3, 3, 3, 3],
            position: ["fixed", "fixed", "fixed", "relative"],
            width: ["80%", "60%", "40%", "100%"],
            backgroundColor: "lightBackground",
            left: [
              showMobileMenu ? 0 : "-100%",
              showMobileMenu ? 0 : "-100%",
              showMobileMenu ? 0 : "-100%",
              "unset",
            ],
            transition: ["left .35s"],
            "-webkit-transform": ["left .35s"],
            top: [0, 0, 0, "unset"],
            height: ["100vh", "100vh", "100vh", "auto"],
          }}
        >
          <Flex sx={{ flexDirection: "row", justifyContent: "space-between" }}>
            <Flex
              sx={{
                flexDirection: ["column", "column", "column", "row"],
                padding: 0,
                margin: 0,
                listStyle: "none",
              }}
              as="ul"
            >
              {menu.map(item => (
                <TextComponent item={item} locale={locale} key={item.id} />
              ))}
            </Flex>
            <Flex
              sx={{
                flexDirection: "row",
                padding: 3,
                margin: 0,
                listStyle: "none",
              }}
            ></Flex>
          </Flex>
        </Box>
      </Container>
    </Box>
  )
}

const TextComponent = ({ item, locale }) => {
  const [show, setShow] = useState(false)

  return (
    <Box
      as="li"
      key={item.id}
      sx={{ position: "relative", paddingX: 4, paddingY: 3 }}
      onMouseEnter={() => setShow(!show)}
      onMouseLeave={() => setShow(!show)}
    >
      {item.link ? (
        <InboundLink
          to={linkSwitch(item.link, locale).href}
          sx={{ fontWeight: "bold" }}
        >
          {item.anchor} +
        </InboundLink>
      ) : (
        <Box sx={{ cursor: "default" }}>{item.anchor}</Box>
      )}
      {item.treeChildren.length > 0 && show && (
        <Box
          as="ul"
          sx={{
            listStyle: "none",
            padding: 3,
            margin: 0,
            backgroundColor: "lightBackground",
            position: "absolute",
            top: 40,
            width: "max-content",
            boxShadow:
              "0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)",
            borderRadius: "sm",
          }}
        >
          {item.treeChildren.map(item =>
            item.anchor ? (
              <Box as="li" key={item.id}>
                {item.link ? (
                  <InboundLink
                    to={linkSwitch(item.link, locale).href}
                    target="blank"
                  >
                    {item.anchor} +
                  </InboundLink>
                ) : (
                  <Box sx={{ cursor: "default" }}>{item.anchor}</Box>
                )}
              </Box>
            ) : null
          )}
        </Box>
      )}
    </Box>
  )
}

export default Nav
