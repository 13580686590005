import {
  Box,
  Container,
  Flex,
  Grid,
  Text,
  Heading,
  Image,
} from "@theme-ui/components"
import { InboundLink, OutboundLink } from "./link"
import React, { useState } from "react"
import { ArrowUp } from "react-feather"
import { useCompany } from "../hooks/useCompany"
import { useFooter } from "../hooks/useFooter"
import linkSwitch from "../utils/linkSwitch"
import logoSocaf from "../images/logo-socaf.svg"
import * as Scroll from "react-scroll"
import { useContacts } from "../hooks/useContacts"

const Footer = () => {
  const footer = useFooter()
  const company = useCompany()
  const contacts = useContacts()

  let scroll = Scroll.animateScroll

  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  const [showScroll, setShowScroll] = useState(false)
  const checkScrollTop = () => {
    if (
      typeof window !== "undefined" &&
      !showScroll &&
      window.pageYOffset > 400
    ) {
      setShowScroll(true)
    } else if (
      typeof window !== "undefined" &&
      showScroll &&
      window.pageYOffset <= 400
    ) {
      setShowScroll(false)
    }
  }
  if (typeof window !== "undefined")
    window.addEventListener("scroll", checkScrollTop)

  return (
    <Box as="footer" sx={{ backgroundColor: "dark", pt: 2, color: "light" }}>
      <Container sx={{ pb: [6, 6, 6, 6] }}>
        <Grid
          columns={["1fr", "1fr", "1.4fr 1fr 1.2fr ", "1.4fr 1fr 1.2fr"]}
          gap={[0, 0, 0, 8]}
        >
          <Box>
            <Box>
              <Flex
                sx={{
                  alignItems: "center",
                  pt: [4],
                  pb: [4],
                }}
              >
                <Heading as="p" variant="h4" sx={{ color: "light", m: 0 }}>
                  Caldofacile by
                </Heading>
                <OutboundLink target="_blank" href="https://socaf.it/">
                  <Image
                    src={logoSocaf}
                    sx={{ ml: [1], width: "80px", height: "auto" }}
                    alt="Socaf Logo"
                  />
                </OutboundLink>
              </Flex>
              <Text
                as="div"
                variant="sectionTitle"
                dangerouslySetInnerHTML={{ __html: company.body }}
              />
            </Box>
          </Box>
          <Box>
            <Heading
              variant="h4"
              sx={{ color: "light", pt: [5], pb: [4], m: 0 }}
            >
              Contattaci
            </Heading>
            <Box>
              <OutboundLink
                sx={{ display: "flex", alignItems: "center", pb: [1] }}
                href={"tel:" + contacts.telefono.replace(/ /g, "")}
              >
                <Text as="p" variant="p" sx={{}}>
                  T {contacts.telefono}
                </Text>
              </OutboundLink>

              <Text as="p" variant="p" sx={{}}>
                F +39 035 4823958
              </Text>

              <Text as="p" variant="p">
                <OutboundLink
                  variant="buttons.primary"
                  sx={{ color: "light" }}
                  href="mailto:info@caldofacile.it"
                >
                  info@caldofacile.it
                </OutboundLink>
              </Text>
            </Box>
          </Box>
          <Box>
            {footer.map(
              column =>
                column.id && (
                  <>
                    <Heading
                      variant="h4"
                      sx={{ color: "light", pt: [5], pb: [4], m: 0 }}
                    >
                      {column.anchor}
                    </Heading>
                    <List key={column.id}>
                      {column.treeChildren.map(link => (
                        <Item>
                          <Text sx={{ fontWeight: "bold", display: "block" }}>
                            {link.link ? (
                              <InboundLink to={linkSwitch(link.link).href}>
                                {linkSwitch(link.link).label}
                              </InboundLink>
                            ) : (
                              link.anchor
                            )}
                          </Text>
                          {link.treeChildren.length > 0 && (
                            <List key={link.id}>
                              {link.treeChildren.map(link => (
                                <Item>
                                  {link.link ? (
                                    <InboundLink
                                      to={linkSwitch(link.link).href}
                                    >
                                      {linkSwitch(link.link).label}
                                    </InboundLink>
                                  ) : (
                                    link.anchor
                                  )}
                                </Item>
                              ))}
                            </List>
                          )}
                        </Item>
                      ))}
                    </List>
                  </>
                )
            )}
          </Box>
        </Grid>
        <Box
          variant="h4"
          sx={{
            color: "light",
            m: 0,
            position: "fixed",
            bottom: [3, 4, 5, 6],
            right: [3, 4, 5, 6],
            alignItems: "center",
            justifyContent: "center",
            width: "48px",
            height: "48px",
            border: "2px solid",
            borderRadius: "1px",
            borderColor: "primary",
            backgroundColor: "dark",
            display: showScroll ? "flex" : "none",
          }}
        >
          <ArrowUp onClick={() => scrollToTop()} />
        </Box>
      </Container>
      <Box sx={{ backgroundColor: "primary", width: "100%" }}>
        <Container sx={{ py: [2, 2, 2, 2] }}>
          <Grid
            columns={[1, "1.5fr .5fr"]}
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            {/* <LanguageSwitcher /> */}
            <Box sx={{ textAlign: ["center", "left"] }}>
              <Text variant="p" sx={{ fontSize: [0] }}>
                © 2020 CALDOFACILE by Socaf | Tutti i diritti riservati. |
                P.Iva: 01331640167 | REA: BG - 197182
              </Text>
            </Box>
            <Box
              sx={{
                textAlign: ["center", "right"],
                color: "textLight",
                fontWeight: "bold",
                py: [3, 0],
              }}
            ></Box>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

const List = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "column",
        margin: 0,
        padding: 0,
        mb: 4,
        listStyle: "none",
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return <Box {...props} as="li" sx={{ mb: 2, "&:last-child": { mb: 0 } }} />
}

export default Footer
